import { Image } from '@finn/ui-components';
import {
  cn,
  getCloudinaryImgUrl,
  getCloudinarySrcSet,
  parseToHtml,
} from '@finn/ui-utils';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import { ContentItemCosmicMetadata } from '~/types/UIModules';

interface IProps {
  item: ContentItemCosmicMetadata;
  shouldPreloadImage?: boolean;
  lineLimit?: number;
}

const ContentItem: React.FunctionComponent<IProps> = ({
  item,
  shouldPreloadImage,
  lineLimit = 3,
}) => {
  const hasLink = Boolean(item.link_href);
  let cloudinaryURL = '';

  const getComponent = () => {
    let srcSet = '';
    if (item.image?.url) {
      cloudinaryURL = getCloudinaryImgUrl(item.image?.url, {
        w: 360,
        dpr: 2,
        ar: '16:9',
        c: 'fill',
      });
      srcSet = getCloudinarySrcSet(item.image?.url, [360]);
    }

    return (
      <div
        className={cn('h-full', {
          'cursor-pointer border-none': hasLink,
        })}
      >
        {item.icon?.url && (
          <Image
            className="mb-4 h-8 w-8"
            src={item.icon?.url}
            alt={item.title}
          />
        )}
        {item.image?.url && (
          <Image
            variant="lazy-loading"
            className="mb-4 w-full"
            src={cloudinaryURL}
            srcSet={srcSet}
            alt={item?.image_alt_text || item.title}
          />
        )}
        <h3 className={cn('global-t5-semibold mb-5', { 'mb-4 mt-0': hasLink })}>
          {' '}
          {parseToHtml(item.title)}
        </h3>
        <p
          className={cn(
            `body-16-light overflow-hidden text-ellipsis pb-1`,
            lineLimit === 3 && 'line-clamp-3',
            lineLimit === 4 && 'line-clamp-4',
            lineLimit === 5 && 'line-clamp-5',
            lineLimit === 6 && 'line-clamp-6',
            { 'mb-3 mt-0': hasLink }
          )}
        >
          {parseToHtml(item.description)}
        </p>
      </div>
    );
  };

  return (
    <>
      {shouldPreloadImage && (
        <Head>
          {item.icon?.url && (
            <link rel="preload" href={item.icon.url} as="image" />
          )}
          {item.image?.url && (
            <link rel="preload" href={cloudinaryURL} as="image" />
          )}
        </Head>
      )}
      {item.link_href ? (
        <Link href={item.link_href}>{getComponent()}</Link>
      ) : (
        getComponent()
      )}
    </>
  );
};

export default ContentItem;
